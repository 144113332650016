import React, { Component } from "react";
import {
  addUserToDiscount,
  deleteOrder,
  deleteUserDiscount,
  getAllCustomers,
  getAllDiscounts,
  getAllProducts,
  getAllUsersDiscount,
  handleDiscount
} from "../../../functions/postFunctions";
import { CustomButton, LinkButton } from "../../../components/Buttons/Button";
import Table from "../../../components/Table/Table";
import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";
import SearchableDropdownMultiple from "../../../components/Form/Dropdown/SearchableDropdownMultiple";

class DiscountsClients extends Component {
  state = {
    theads: ["Klijent", "Kompanija", "Izbriši"],
    customers: [],
    removeLoadMore: true,
    page: 1,
    categoryList: [],
    currentCategory: null,
    discounts: [],
    clientType: [],
    activeCustomers: []
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Klijenti");
    getAllDiscounts().then(res => {
      if (res.success) {
        const discounts = res.discounts.map(e => ({
          label: e.name,
          value: e.id
        }));
        discounts.unshift({ label: "Bez popusta", value: 0 });
        this.setState({ discounts });
      }
    });
    getAllUsersDiscount(this.props.match.params.id).then(res => {
      if (res.success) {
        this.setState({ activeCustomers: res.customers }, this.getCustomers);
      }
    });
  }
  filterObjectArray = (arr, filterArr) => {
    let filtered = [];
    arr.forEach(a => {
      let test = true;
      filterArr.forEach(f => {
        if (f.id === a.id) {
          test = false;
        }
      });
      test && filtered.push(a);
    });
    return filtered;
  };

  getCustomers = () => {
    getAllCustomers(1).then(res => {
      if (res.success) {
        const customers = this.filterObjectArray(
          res.customers,
          this.state.activeCustomers
        );
        this.setState({
          customers: customers.map(e => ({
            value: e.id,
            label: e.company_name
          }))
        });
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentCategory !== this.state.currentCategory &&
      this.state.currentCategory
    ) {
      this.loadNews();
    }
    if (prevState.clientType !== this.state.clientType) {
      this.getCustomers();
    }
    if (prevState.activeCustomers !== this.state.activeCustomers) {
      const { customers, activeCustomers } = this.state;
      this.setState({
        customers: this.filterObjectArray(customers, activeCustomers).map(
          e => ({
            value: e.id,
            label: e.company_name
          })
        ),
        clientType: []
      });
    }
  }

  handleDeletePage = id => {
    const { orders } = this.state;
    const newOrders = orders.filter(e => e.id !== id);
    deleteOrder(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ orders: newOrders });
      }
    });
  };

  loadNews = () => {
    const { currentCategory } = this.state;
    getAllProducts(20, 0, currentCategory.value).then(res => {
      if (res.success) {
        this.setState({
          products: res.products,
          page: 1,
          removeLoadMore: res.products.length < 20
        });
      }
    });
  };

  loadMoreNews = () => {
    const { page, currentCategory } = this.state;
    getAllProducts(20, page * 20, currentCategory.value).then(res => {
      if (res.success) {
        this.setState(({ products, page }) => ({
          products: [...products, ...res.products],
          page: page + 1,
          removeLoadMore: res.products.length < 20
        }));
      }
    });
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleDiscountChange = (user_id, value) => {
    const index = this.state.customers.findIndex(e => e.id === user_id);
    if (index > -1) {
      this.setState(({ customers }) => ({
        customers: [
          ...customers.slice(0, index),
          {
            ...customers[index],
            discount_id: value.value
          },
          ...customers.slice(index + 1)
        ]
      }));
      handleDiscount(
        { id: user_id, discount_id: value.value },
        this.props.token
      ).then(res => {
        if (res.success) {
          this.getCustomers();
        }
      });
    }
  };

  handleAddClient = () => {
    const { value } = this.state.clientType;
    addUserToDiscount(value, this.props.match.params.id, this.props.token).then(
      res => {
        if (res.success) {
          this.setState({ activeCustomers: res.customers });
        }
      }
    );
  };

  handleDeleteUserDiscount = (user_id, discount_id) => {
    deleteUserDiscount({ user_id, discount_id }, this.props.token).then(res => {
      if (res.success) {
        this.setState({ activeCustomers: res.customers }, this.getCustomers);
      }
    });
  };

  render() {
    const { theads, customers, clientType, activeCustomers } = this.state;
    const categories = [
      { value: null, label: "Svi korisnici" },
      { value: 0, label: "Fizička lica" },
      { value: 1, label: "Pravna lica" }
    ];
    return (
      <div>
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-md-3">
              <SearchableDropdownMultiple
                data={customers}
                placeholder="Izaberi klijenta"
                name="clientType"
                handleChange={this.handleInput}
                value={clientType}
              />
            </div>
            {clientType && clientType.value > 0 ? (
              <div className="col-md-3">
                <CustomButton onClick={this.handleAddClient}>
                  Dodaj klijenta
                </CustomButton>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Table theads={theads}>
          {activeCustomers.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <div className="wrapper d-flex flex-column">
                    <span className="f-s-16">{e.name}</span>
                  </div>
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.company_name}</span>
                </td>
                <td className="td px-10">
                  <CustomButton
                    onClick={() =>
                      this.handleDeleteUserDiscount(e.user_id, e.discount_id)
                    }
                    className="red"
                  >
                    Izbriši
                  </CustomButton>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    );
  }
}

export default DiscountsClients;
