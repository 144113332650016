import { CustomButton } from "../../../components/Buttons/Button";
import React, { Component } from "react";
import Pagination from "react-js-pagination";

import {
  deleteProduct,
  getAllDiscountedProducts,
  getAllProductCategory,
  getAllProducts,
  getCustomer,
  getSpecificDiscounts,
  getUserSpecificDiscount,
  handleSpecificDiscount,
  toggleSpecialOffer
} from "../../../functions/postFunctions";

import { CustomInput } from "../../../components/Form/Inputs/Inputs";
import SearchableDropdownMultiple from "../../../components/Form/Dropdown/SearchableDropdownMultiple";
import SortProducts from "../../../components/SortProducts/SortProducts";
import Table from "../../../components/Table/Table";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import SearchableDropdown from "../../../components/Form/Dropdown/SearchableDropdown";

class DiscountDetails extends Component {
  state = {
    theads: ["Cover", "Naziv", "Popust"],
    products: [],
    removeLoadMore: true,
    page: 1,
    categoryList: [],
    currentCategory: null,
    sortProductsModal: false,
    sortableCategories: [],
    activePage: 1,
    max: 0,
    product_code: "",
    deleteModal: false,
    deleteID: 0,
    name: "",
    discounts: [],
    customer: null,
    specificDiscounts: [],
    discountFilterOptions: [],
    discountFilter: { value: 0, label: "Svi popusti" },
    multipleDiscounts: []
  };

  componentDidMount() {
    getCustomer(this.props.match.params.id).then(res => {
      if (res.success) {
        let name = res.customer.company_name;
        this.setState({ customer: res.customer });
        this.props.setBreadcrumbs(name);
        getSpecificDiscounts(res.customer.id).then(res => {
          if (res.success) {
            this.setState({ specificDiscounts: res.specificDiscounts });
          }
        });
      }
    });

    if (parseInt(this.props.match.params.page) < 1) {
      this.props.history.push("1");
    }

    let categories = [];
    let sortableCategories = [];
    const handleRecursion = arr => {
      arr.forEach(e => {
        categories.push({ value: e.id, label: e.name });
        if (e.children.length > 0) {
          handleRecursion(e.children);
        } else {
          if (e.id !== 290) {
            sortableCategories.push(e);
          }
        }
      });
    };

    getAllProductCategory().then(res => {
      if (res.success) {
        handleRecursion(res.categories);
        const categories = this.findAllMenus(res.categories);
        this.setState({
          categoryList: categories,
          currentCategory: {
            value: 0,
            label: "Svi proizvodi"
          },
          sortableCategories,
          activePage: parseInt(this.props.match.params.page)
        });
      }
    });
    getUserSpecificDiscount(this.props.match.params.id).then(res => {
      if (res.success) {
        const discounts = res.discounts.map(e => ({
          label: e.discount_name,
          value: e.discount_id
        }));
        const discountFilterOptions = [...discounts];
        discounts.unshift({ label: "Defaultni popust", value: 0 });
        discountFilterOptions.unshift({ label: "Svi popusti", value: 0 });
        this.setState({ discounts, discountFilterOptions });
      }
    });
  }

  findAllMenus = array => {
    let menus = [];
    const handleRecursion = arr => {
      arr.forEach(e => {
        menus.push(e);
        if (e.children.length > 0) {
          handleRecursion(e.children);
        }
      });
    };
    handleRecursion(array);
    return menus;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentCategory !== this.state.currentCategory &&
      this.state.currentCategory
    ) {
      this.loadNews();
    }
    if (prevProps.match.params.page !== this.props.match.params.page) {
      this.setState({ activePage: parseInt(this.props.match.params.page) });
      this.loadMoreNews();
    }
    if (prevState.name !== this.state.name) {
      this.loadNews();
      this.props.history.push("1");
    }
    if (prevState.discountFilter !== this.state.discountFilter) {
      this.loadNews();
      this.props.history.push("1");
    }
    if (prevState.multipleDiscounts !== this.state.multipleDiscounts) {
      if (this.state.multipleDiscounts.value) {
        this.setState({
          theads: ["Cover", "Naziv", "Trenutni Popust", "Novi popust"]
        });
      } else {
        this.setState({ theads: ["Cover", "Naziv", "Popust"] });
      }
    }
  }

  showModal = id => {
    this.setState({ deleteID: id, deleteModal: true });
  };

  removeModal = () => {
    this.setState({ deleteModal: false, deleteID: 0 });
  };

  handleDeletePage = id => {
    const { products } = this.state;
    const newProducts = products.filter(e => e.id !== id);
    deleteProduct(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({
          products: newProducts,
          deleteModal: false,
          deleteID: 0
        });
      }
    });
  };

  loadNews = () => {
    const { currentCategory, name, discountFilter, customer } = this.state;

    if (discountFilter.value === 0) {
      getAllProducts(
        20,
        (parseInt(this.props.match.params.page) - 1) * 20,
        currentCategory.value,
        name !== "" ? name : null
      ).then(res => {
        if (res.success) {
          this.setState({
            products: res.products,
            max: res.products.length > 0 ? res.total : 0,
            page: 1
          });
        }
      });
    } else {
      getAllDiscountedProducts(customer.id, discountFilter.value).then(res => {
        if (res.success) {
          this.setState({
            products: res.products,
            max: 0,
            page: 1
          });
        }
      });
    }
  };

  loadMoreNews = () => {
    const { page, currentCategory } = this.state;
    getAllProducts(
      20,
      (parseInt(this.props.match.params.page) - 1) * 20,
      currentCategory.value
    ).then(res => {
      if (res.success) {
        this.setState(({ products, page }) => ({
          products: res.products,
          page: page + 1,
          max: res.products.length > 0 ? res.total : 0
        }));
      }
    });
  };

  handleInput = (name, value) => {
    if (name === "currentCategory") {
      this.props.history.push("1");
    }
    this.setState({
      [name]: value
    });
  };

  toggleModal = () => {
    this.setState(
      ({ sortProductsModal }) => ({
        sortProductsModal: !sortProductsModal
      }),
      () => {
        if (!this.state.sortProductsModal) {
          this.loadNews();
        }
      }
    );
  };

  handleCheckboxChange = (id, index, val) => {
    const { customer } = this.state;
    const { value } = this.state.multipleDiscounts;
    if (val) {
      const data = { user_id: customer.id, product_id: id, discount_id: value };
      handleSpecificDiscount(data, this.props.token).then(res => {
        if (res.success) {
          this.setState({ specificDiscounts: res.specificDiscounts });
        }
      });
    } else {
      const data = { user_id: customer.id, product_id: id, discount_id: 0 };
      handleSpecificDiscount(data, this.props.token).then(res => {
        if (res.success) {
          this.setState({ specificDiscounts: res.specificDiscounts });
        }
      });
    }
  };

  handleDiscountChange = (product_id, value) => {
    const index = this.state.products.findIndex(e => e.id === product_id);
    if (index > -1) {
      this.setState(({ products }) => ({
        products: [
          ...products.slice(0, index),
          {
            ...products[index],
            discount_id: value.value
          },
          ...products.slice(index + 1)
        ]
      }));
      const { id } = this.state.customer;
      const data = { user_id: id, product_id, discount_id: value.value };
      handleSpecificDiscount(data, this.props.token).then(res => {
        if (res.success) {
          this.setState({ specificDiscounts: res.specificDiscounts });
        }
      });
    }
  };

  handlePageChange = pageNumber => {
    this.props.history.push(`${pageNumber}`);
  };

  redirectAddProduct = () => {
    this.props.history.push("/dodaj-proizvod");
  };

  findDiscount = product_id => {
    const discount = this.state.specificDiscounts.find(e => {
      return e.product_id === product_id;
    });
    if (discount) {
      return this.state.discounts.find(e => {
        return e.value === discount.discount_id;
      });
    } else {
      return this.state.discounts[0];
    }
  };

  handleMultipleDiscountChange = (name, value) => {
    this.setState({ [name]: value });
  };

  removeMultipleDiscount = () => {
    this.setState({ multipleDiscounts: [] });
  };

  isChecked = id => {
    const { value } = this.state.multipleDiscounts;
    return (
      this.state.specificDiscounts.filter(
        f => f.product_id === id && f.discount_id === value
      ).length > 0
    );
  };

  render() {
    const {
      theads,
      products,
      categoryList,
      currentCategory,
      sortProductsModal,
      deleteModal,
      deleteID,
      discounts,
      discountFilterOptions,
      discountFilter
    } = this.state;
    localStorage.setItem("page", this.state.activePage);
    let options = [];
    let option = null;
    categoryList.forEach(e => {
      if (e.level === 0) {
        option = null;
        option = {
          label: e.name,
          options: [{ value: e.id, label: e.name }]
        };
        if (option) {
          options.push(option);
        }
      } else {
        option.options.push({ value: e.id, label: e.name });
      }
    });
    const categories = [
      {
        value: 0,
        label: "Svi proizvodi"
      },
      ...options
    ];
    return (
      <div>
        <DeleteModal
          visible={deleteModal}
          category="proizvod"
          removeModal={this.removeModal}
          deleteItem={this.handleDeletePage}
          id={deleteID}
        />
        <SortProducts
          hideModal={this.toggleModal}
          visible={sortProductsModal}
          sortableCategories={options.filter(e => e.label !== "Abacus")}
          token={this.props.token}
        />
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-12 mb-20">
              <h3 className="f-s-20 text-color-font-l f-w-5">Filtriraj:</h3>
            </div>
            <div className="col-md-3">
              <SearchableDropdown
                data={discountFilterOptions}
                placeholder="Popust"
                name="discountFilter"
                handleChange={this.handleInput}
                value={discountFilter}
              />
            </div>
            {discountFilter.value === 0 && (
              <>
                <div className="col-md-3">
                  <SearchableDropdownMultiple
                    data={categories}
                    placeholder="Kategorija"
                    name="currentCategory"
                    handleChange={this.handleInput}
                    value={currentCategory}
                  />
                </div>
                <div className="col-md-3">
                  <CustomInput
                    label="Naziv"
                    value={this.state.name}
                    handleChange={this.handleInput}
                    name="name"
                    index={0}
                    fast
                  />
                </div>
              </>
            )}
          </div>
          <div className="row mt-50">
            <div className="col-12">
              <h3 className="f-s-20 text-color-font-l f-w-5">
                Selektuj popust za dodavanje:
              </h3>
            </div>
            <div className="col-3 mt-20">
              <div>
                <SearchableDropdown
                  data={discounts.filter(e => e.value !== 0)}
                  placeholder="Popust"
                  name="multipleDiscounts"
                  handleChange={this.handleMultipleDiscountChange}
                  value={this.state.multipleDiscounts}
                />
              </div>
            </div>
            {this.state.multipleDiscounts.value && (
              <div className="col-4 mt-20">
                <CustomButton onClick={this.removeMultipleDiscount}>
                  Završi
                </CustomButton>
              </div>
            )}
          </div>
        </div>
        <Table theads={theads}>
          {products.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <img
                    src={`/${e.cover}`}
                    alt={e.slug}
                    style={{ maxHeight: "50px" }}
                  />
                </td>
                <td className="td px-10">
                  <span className="f-s-16">{e.name}</span>
                </td>
                <td className="td px-10">
                  <div style={{ minWidth: "300px" }}>
                    {!this.state.multipleDiscounts.value ? (
                      <SearchableDropdown
                        data={discounts}
                        placeholder="Popust"
                        name={e.id}
                        handleChange={this.handleDiscountChange}
                        value={this.findDiscount(e.id)}
                      />
                    ) : (
                      <span className="f-s-16">
                        {this.findDiscount(e.id).label}
                      </span>
                    )}
                  </div>
                </td>
                {this.state.multipleDiscounts.value && (
                  <td className="td px-10">
                    <div className="d-flex align-items-center">
                      <span className="f-s-16 mr-10 lh">
                        <input
                          type="checkbox"
                          checked={this.isChecked(e.id)}
                          onChange={ev =>
                            this.handleCheckboxChange(
                              e.id,
                              index,
                              ev.target.checked
                            )
                          }
                        />
                      </span>
                      <span className="f-s-16 text-color-font-l lh">
                        {this.state.multipleDiscounts.label}
                      </span>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </Table>
        <div className="wrapper mt-40 d-flex justify-content-center">
          <Pagination
            hideDisabled
            activePage={this.state.activePage}
            itemsCountPerPage={20}
            totalItemsCount={this.state.max}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default DiscountDetails;
