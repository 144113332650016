import React, { Component } from "react";

import { CustomButton } from "../Buttons/Button";
import { CustomInput } from "../Form/Inputs/Inputs";
import { addCompetitionValidation } from "../../functions/validateFunctions";
import { editCompetition } from "../../functions/postFunctions";

class DeleteModal extends Component {
  render() {
    const { visible, category, removeModal, deleteItem, id } = this.props;
    return (
      <div
        className={`addTeamModal d-flex justify-content-center align-items-center ${
          visible ? "visible" : ""
        }`}
      >
        <div className="addTeamContent py-30 px-30">
          <h4 className="text-color-primary f-s-20 lh text-center mb-20">
            Da li ste sigurni da želite da izbrišete {category}?
          </h4>
          <div className="row mt-40">
            <div className="col-lg-12 d-flex justify-content-center">
              <CustomButton
                className="mr-30 red"
                onClick={() => deleteItem(id)}
              >
                Izbriši {category}
              </CustomButton>
              <CustomButton onClick={() => removeModal()}>
                Odustani
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteModal;
