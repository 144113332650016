import React, { Component } from "react";
import {
  deleteOrder,
  getAllCustomers,
  getAllDiscounts,
  getAllProducts,
  getAllUserSpecificDiscount,
  handleDiscount
} from "../../functions/postFunctions";
import { CustomButton, LinkButton } from "../../components/Buttons/Button";
import Table from "../../components/Table/Table";
import SearchableDropdown from "../../components/Form/Dropdown/SearchableDropdown";
import SearchableDropdownMultiple from "../../components/Form/Dropdown/SearchableDropdownMultiple";

class CustomersPage extends Component {
  state = {
    theads: ["Klijent", "Popust", "Detalji"],
    customers: [],
    removeLoadMore: true,
    page: 1,
    categoryList: [],
    currentCategory: null,
    discounts: [],
    clientType: { value: null, label: "Svi klijenti" },
    userSpecificDiscounts: []
  };

  componentDidMount() {
    this.props.setBreadcrumbs("Klijenti");

    getAllDiscounts().then(res => {
      if (res.success) {
        const discounts = res.discounts.map(e => ({
          label: e.name,
          value: e.id
        }));
        discounts.unshift({ label: "Bez popusta", value: 0 });
        this.setState({ discounts });
      }
    });
    getAllUserSpecificDiscount().then(res => {
      if (res.success) {
        this.setState({ userSpecificDiscounts: res.discounts });
        this.getCustomers();
      }
    });
  }

  getCustomers = () => {
    const { clientType } = this.state;
    if (clientType.value !== null) {
      getAllCustomers(clientType.value).then(res => {
        if (res.success) {
          this.setState({ customers: res.customers });
        }
      });
    } else {
      getAllCustomers().then(res => {
        if (res.success) {
          this.setState({ customers: res.customers });
        }
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentCategory !== this.state.currentCategory &&
      this.state.currentCategory
    ) {
      this.loadNews();
    }
    if (prevState.clientType !== this.state.clientType) {
      this.getCustomers();
    }
  }

  handleDeletePage = id => {
    const { orders } = this.state;
    const newOrders = orders.filter(e => e.id !== id);
    deleteOrder(id, this.props.token).then(res => {
      if (res.success) {
        this.setState({ orders: newOrders });
      }
    });
  };

  loadNews = () => {
    const { currentCategory } = this.state;
    getAllProducts(20, 0, currentCategory.value).then(res => {
      if (res.success) {
        this.setState({
          products: res.products,
          page: 1,
          removeLoadMore: res.products.length < 20
        });
      }
    });
  };

  loadMoreNews = () => {
    const { page, currentCategory } = this.state;
    getAllProducts(20, page * 20, currentCategory.value).then(res => {
      if (res.success) {
        this.setState(({ products, page }) => ({
          products: [...products, ...res.products],
          page: page + 1,
          removeLoadMore: res.products.length < 20
        }));
      }
    });
  };

  handleInput = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleDiscountChange = (user_id, value) => {
    const index = this.state.customers.findIndex(e => e.id === user_id);
    if (index > -1) {
      this.setState(({ customers }) => ({
        customers: [
          ...customers.slice(0, index),
          {
            ...customers[index],
            discount_id: value.value
          },
          ...customers.slice(index + 1)
        ]
      }));
      handleDiscount(
        { id: user_id, discount_id: value.value },
        this.props.token
      ).then(res => {
        if (res.success) {
          this.getCustomers();
        }
      });
    }
  };

  findUserSpecificDiscount = (userSpecificDiscounts, id) => {
    return [
      { label: "Bez popusta", value: 0 },
      ...userSpecificDiscounts
        .filter(f => f.user_id === id)
        .map(e => ({ value: e.id, label: e.discount_name }))
    ];
  };

  render() {
    const {
      theads,
      customers,
      discounts,
      clientType,
      userSpecificDiscounts
    } = this.state;
    const categories = [
      { value: null, label: "Svi korisnici" },
      { value: 0, label: "Fizička lica" },
      { value: 1, label: "Pravna lica" }
    ];
    return (
      <div>
        <div className="pageTop mb-40">
          <div className="row">
            <div className="col-md-3">
              <SearchableDropdownMultiple
                data={categories}
                placeholder="Kategorija"
                name="clientType"
                handleChange={this.handleInput}
                value={clientType}
              />
            </div>
          </div>
        </div>
        <Table theads={theads}>
          {customers.map((e, index) => {
            return (
              <tr key={index}>
                <td className="td px-10">
                  <div className="wrapper d-flex flex-column">
                    <span className="f-s-16">{e.company_name}</span>
                    <span className="f-s-16">{e.email}</span>
                    <span className="f-s-16">{e.phone}</span>
                  </div>
                </td>
                <td className="td px-10">
                  <SearchableDropdown
                    data={this.findUserSpecificDiscount(
                      userSpecificDiscounts,
                      e.id
                    )}
                    placeholder="Popust"
                    name={e.id}
                    handleChange={this.handleDiscountChange}
                    value={discounts.find(el => el.value === e.discount_id)}
                  />
                </td>
                {this.findUserSpecificDiscount(userSpecificDiscounts, e.id)
                  .length > 1 && (
                  <td className="td px-10">
                    <LinkButton href={`klijenti/${e.id}/1`}>Detalji</LinkButton>
                  </td>
                )}
              </tr>
            );
          })}
        </Table>
        {!this.state.removeLoadMore ? (
          <div className="wrapper mt-40 d-flex justify-content-center">
            <CustomButton onClick={this.loadMoreNews}>Učitaj još</CustomButton>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default CustomersPage;
